@import "../../fonts/clash-display/css/clash-display.scss";
@import "../variables";
@import "../helpers/mixins";

.home {
  .section-nw {
    padding-bottom: 0;
    background: $primary2;
    @include dark {
      background: $neutrals0;
    }
  }

  .section-rv {
    background: $primary2;
    @include dark {
      background: $neutrals0;
    }
  }

  .section-dark {
    background: $primary1;
  }

  &__hero {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include t {
      display: grid;
    }

    &__section {
      width: 479px;

      @include t {
        width: 100%;
      }
    }

    &__title {
      display: flex;
      flex-wrap: wrap;
      @include outfit;
      font-size: 60px;
      font-weight: 700;
      line-height: 90px;
      text-align: left;
      margin-bottom: 50px;

      @include t {
        font-size: 50px;
        display: flex;
        justify-content: center;
      }

      @include m {
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 600;
        line-height: 43.2px;
        text-align: center;
      }
    }

    &__text {
      color: $neutrals4;
      @include poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      padding-right: 5px;

      @include m {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }

      @include t {
        margin-bottom: 20px;
        text-align: center;
      }
    }

    &__section-img {
      width: 738px;

      @include t {
        width: 100%;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
    }

    &__dark {
      display: none;

      @include dark {
        display: block;
      }
    }

    &__light {
      display: block;

      @include dark {
        display: none;
      }
    }
  }

  .hero {
    &__site_logo {
      img {
        // width: 75%;
        width: 55%;
        margin-bottom: 2rem;

        @include t {
          display: none;

          @include dark {
            display: none;
          }
        }
      }
    }

    &__title {
      margin-top: 30px;
    }
  }

  &__nftMerketplace {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 80px;

    @include t {
      display: grid;
      // margin-bottom: 30px;
      width: 100%;
      justify-content: center;
    }

    &_head {
      margin-bottom: 80px;
      display: flex;
      align-items: center;
      @include t {
        margin-bottom: 30px;
      }
    }

    &__title {
      @include poppins;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-align: left;
      display: flex;
      align-items: center;
      color: $neutrals2;
      margin-right: auto;

      &.is-center {
        justify-content: center;
      }

      @include dark {
        color: $coconut;
      }

      @include m {
        font-size: 22px;
        letter-spacing: -0.01em;
        text-align: left;
      }
    }

    &__btnExploreforPC {
      @include t {
        display: none;
      }
    }

    &__section {
      display: flex;
      justify-content: center;
      gap: 20px;
      width: 100%;
      height: 585px;
      margin-top: 80px;

      @include r(1150) {
        display: grid;
        justify-content: center;
        height: 100%;
      }

      @include m {
        margin-top: 10px;
      }
    }

    &__section1 {
      display: grid;
      gap: 22.5px;
      width: 485px;

      @include r(1150) {
        width: 100%;
        display: flex;
        justify-content: center;
        height: auto;
      }

      @include m {
        min-height: 531px;
        margin-bottom: 50px;
        width: 100%;
        display: grid;
        justify-content: center;
        height: auto;
      }
    }

    &__section2 {
      display: grid;
      gap: 22.5px;
      width: 335px;

      @include r(1150) {
        width: 100%;
        display: flex;
        justify-content: center;
        height: auto;
      }

      @include m {
        min-height: 531px;
        margin-bottom: 30px;
        width: 100%;
        display: grid;
        justify-content: center;
        height: auto;
      }
    }

    &__section2-1 {
      display: grid;
      gap: 22.5px;
      width: 335px;

      @include r(1150) {
        width: 100%;
        display: flex;
      }

      @include m {
        min-height: 531px;
        margin-bottom: 30px;
        width: 100%;
        display: grid;
        justify-content: center;
      }
    }

    &__section2-2 {
      display: grid;
      gap: 22.5px;
      width: 335px;

      @include r(1150) {
        width: 100%;
        display: grid;
      }

      @include m {
        min-height: 531px;
        margin-bottom: 30px;
        width: 100%;
        display: grid;
        justify-content: center;
      }
    }

    &__section3 {
      display: grid;
      gap: 22.5px;
      width: 450px;

      @include r(1150) {
        gap: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        height: auto;
      }

      @include m {
        min-height: 531px;
        margin-bottom: 30px;
        width: 100%;
        display: grid;
        justify-content: center;
        height: auto;
      }
    }

    &_box-photographer {
      padding-top: 20px !important;
    }

    &__box {
      width: 100%;
      padding: 20px;
      gap: 0px;
      border-radius: 10px;
      border: 0.86px;
      justify-content: space-between;
      background: $neutrals8;
      border: 1px solid $neutrals6;

      @include dark {
        background: $primary5;
        border: 1px solid $navy1;
      }

      @include m {
        max-width: 100%;
        padding: 10px;
      }
    }

    &__box1 {
      display: grid;
      gap: 10px;
    }

    &__text {
      color: $black;
      @include poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

      @include dark {
        color: $white;
      }
    }

    &__detail {
      color: $neutrals4;
      @include poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    &__text2 {
      flex: 0 0 31%;
      background: linear-gradient(114.96deg, #8f7816 25.88%, #ffd630 92.33%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @include poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

      @include r(1600) {
        font-size: 14px;
      }

      @include r(1150) {
        font-size: 16px;
      }
    }

    &__img {
      margin-top: 40px;
      position: relative;
      border-radius: 50px;
      -webkit-backdrop-filter: blur(80px);
      backdrop-filter: blur(80px);
      display: flex;
      align-items: center;
      max-width: 90%;
      height: 35px;
      z-index: 0;
      transition: all 0.2s;
      @include poppins;

      @include r(1600) {
        margin-top: 30px;
      }

      @include r(1150) {
        margin-top: 40px;
      }
    }

    &__img1 {
      position: relative;
      border-radius: 50px;
      -webkit-backdrop-filter: blur(80px);
      backdrop-filter: blur(80px);
      display: flex;
      align-items: center;
      max-width: 90%;
      height: 35px;
      z-index: 0;
      transition: all 0.2s;
      @include poppins;
    }

    &__avator {
      position: relative;
      flex-shrink: 0;
      width: 35px;
      height: 35px;
      border: 2px solid $neutrals6;
      margin-right: 5px;
      border-radius: 50px;

      @include dark {
        border: 2px solid $black;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50px;
      }

      &:not(:first-child) {
        margin-left: -14px;
      }
    }

    &__avator1 {
      position: relative;
      flex-shrink: 0;
      width: 23px;
      height: 23px;

      margin-right: 5px;
      border-radius: 50px;

      // @include dark {
      //   border: 2px solid $black;
      // }

      & > img {
        // border: 2px solid $neutrals6;
        // @include dark {
        //   border: 2px solid $neutrals3;
        // }
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50px;
      }

      &:not(:first-child) {
        margin-left: -14px;
      }
    }

    &__box2 {
      padding: 20px;
      width: 100%;
      gap: 10px;
      border-radius: 10px;
      border: 0.86px;
      justify-content: space-between;
      background: $coconut2;
      border: 1px solid $neutrals6;

      @include dark {
        background: $primary6;
        border: 1px solid $navy1;
      }
      @include m {
        max-width: 100%;
        padding: 10px;
      }
    }

    &__guides-text {
      padding: 5px 15px 5px 15px;
      @include poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 0px;
      text-align: left;
      color: $black;

      @include dark {
        color: $white;
      }
    }

    &__section-user {
      margin: 10px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__user-text {
      color: $black;
      @include poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

      @include dark {
        color: $white;
      }
    }

    &__precent-text {
      @include poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: $primary4;

      @include dark {
        color: #a08846;
      }
    }

    &__tag {
      padding: 0px 6px 0px 6px;
      gap: 10px;
      border-radius: 4px;
      background: #8e793e1a;
      color: $primary4;
      @include poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

      @include dark {
        color: #a08846;
        background: #a088461a;
      }
    }

    &__tags-length {
      padding: 0px 6px 0px 6px;
      gap: 10px;
      color: $primary4;
      @include poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;

      @include dark {
        color: #a08846;
      }
    }

    &__section-various {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 15px;
    }

    &__various-text {
      margin-bottom: 10px;
      @include poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: $black;

      @include dark {
        color: $white;
      }
    }

    &__ul {
      display: grid;
      gap: 1px;
    }

    &__li-text {
      list-style-type: disc;
      @include poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: $neutrals4;
      margin-left: 20px;
    }

    &__section3-box {
      display: grid;
      gap: 34px;

      @include m {
        gap: 20px;
      }
    }

    &__section3-img {
      border-radius: 6px;
      object-fit: cover;
      width: 406px;

      @include r(1600) {
        width: 376px;
      }

      @include r(1250) {
        width: 290px;
      }

      @include r(1150) {
        height: 100%;
        width: 100%;
      }

      @include m {
        width: 100%;
      }
    }

    &__section3-video {
      border-radius: 6px;
      width: 100%;
      height: 60vh;

      @include w {
        height: 30vh;
      }

      @include m {
        width: 100%;
        height: 200px;
      }
    }

    &__mobile {
      display: none;

      @include m {
        width: 100%;
        display: grid;
        justify-content: center;
      }
    }

    &__mobile2 {
      display: none;

      @include m {
        display: block;
        position: relative;
      }
    }

    &__mobile2-margin {
      display: none;

      @include m {
        display: block;
        margin-top: 10px;
      }
    }

    // &__swiper-width {
    //   @include r(580) {
    //     max-width: 400px !important;
    //     margin-right: 180px !important;
    //   }
    //   @include r(500) {
    //     max-width: 300px !important;
    //     margin-right: 90px !important;
    //   }
    // }

    &__pc {
      display: grid;

      @include r(1150) {
        display: flex;
      }

      @include m {
        display: none;
      }
    }

    &__pc2 {
      display: block;

      @include t {
        display: block;
      }

      @include m {
        display: none;
      }
    }

    &__slider {
      width: 100% !important;
      display: grid !important;
    }

    &_exclusive {
      display: flex;
      width: 100%;
      margin-top: 30px;
      gap: 18px;

      @include m {
        gap: 8px;
        max-width: 450px;
      }
    }

    &__gallery1 {
      display: grid;
      margin: 10px 20px 10px 0px;
      gap: 8px;
      grid-template-columns: repeat(1, 1fr);
      position: relative;
    }

    &__gallery2 {
      display: grid;
      margin: 10px 20px 10px 0px;
      gap: 8px;
      grid-template-columns: repeat(2, 1fr);
      position: relative;
    }

    &__gallery3 {
      display: grid;
      // margin: 10px 20px 10px 0px;
      border: 1px solid;
      border-color: $neutrals6;
      gap: 8px;
      grid-template-columns: repeat(3, 1fr);
      position: relative;
      flex: 1;
      padding: 5px;

      @include dark {
        border-color: $navy1;
      }
    }

    &__preview {
      grid-column: 1/3;
      grid-row: 1/3;

      img,
      video {
        width: 100%;
        border-radius: 0px;
        height: 260px;
        object-fit: cover;
      }
    }

    &__preview_2 {
      &:first-child {
        grid-column: 1/2;
        grid-row: 1/2;
      }

      &:nth-child(2) {
        grid-column: 2/2;
        grid-row: 1/2;
      }

      img,
      video {
        width: 100%;
        border-radius: 0px;
        height: 260px;
        object-fit: cover;
      }
    }

    &__preview_3 {
      &:first-child {
        grid-column: 3;
        grid-row: 1;
        height: 35px;

        @include r(1600) {
          height: 20px;
        }

        @include r(1150) {
          height: 30px;
        }

        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &:nth-child(2) {
        grid-column: 3;
        grid-row: 2;
        height: 35px;

        @include r(1600) {
          height: 20px;
        }

        @include r(1150) {
          height: 30px;
        }

        img,
        video {
          object-fit: cover;
        }
      }

      &:nth-child(3) {
        grid-column: 3 / 1;
        grid-row: 3 / 1;
        height: 80px;

        @include r(1600) {
          height: 50px;
        }

        @include r(1150) {
          height: 70px;
        }

        img,
        video {
          object-fit: cover;
        }
      }

      img,
      video {
        width: 100%;
        border-radius: 0px;
        object-fit: cover;
        height: 100%;
      }
    }

    &__section-Guides {
      margin-bottom: 4px;
      display: flex;
      gap: 10px;
      justify-content: flex-start;
    }

    &__box-Guides {
      background-color: $white;
      border-radius: 17.32px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include dark {
        background-color: $black;
      }

      @include t {
        width: 33.33%;
      }
    }

    &__box-join {
      margin: 100px 0px;
      display: flex;
      padding: 70px 80px;
      align-items: center;
      width: 100%;
      gap: 0px;
      border-radius: 10px;
      border: 0.86px;
      justify-content: space-between;
      background: $neutrals8;
      border: 1px solid $neutrals6;

      @include dark {
        background: $primary5;
        border: 1px solid $navy1;
      }

      @include t {
        display: block;
        padding: 40px 30px;
        text-align: center;
      }

      @include m {
        margin: 30px 0px;
        display: block;
        padding: 40px 30px;
        text-align: start;
      }
    }
  }

  &__seedToken {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include t {
      display: grid;
      justify-content: center;
    }

    &__icon {
      width: 24px;
      height: 24px;

      @include m {
        width: 16px;
        height: 16px;
      }
    }

    &__title {
      color: $black !important;

      @include dark {
        color: $white !important;
      }
    }

    &__icon1 {
      width: 44px;
      height: 44px;
    }

    &__icons {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .icon_holder {
        display: flex;
        align-items: center;
      }
    }

    &__section1 {
      width: 470px;

      @include t {
        width: 100%;
      }
    }

    &__img {
      width: 50%;
      height: 50%;

      @include t {
        display: none;
      }
    }

    &__img2 {
      display: none;

      @include t {
        margin-top: 20px;
        display: flex;
        width: 100%;
        height: 100%;
      }
    }

    &__items {
      margin-top: 80px;
      display: grid;
      gap: 20px;

      @include t {
        margin-top: 40px;
      }
    }

    &__item {
      max-height: 50px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 14px 16px 14px 16px;
      gap: 20px;
      border-radius: 10px;
      border: 1px solid $neutrals6;
      box-shadow: none;

      @include dark {
        border: 1px solid $navy1;
      }

      @include m {
        max-height: 41px;
        gap: 8px;
      }

      &:hover {
        background: $white;
        box-shadow: 0px 7px 28.8px #1f2f461f;
        border: 0px;

        @include dark {
          background: $black;
          box-shadow: 0px 7px 28.8px #1f2f461f;
        }
      }

      @include m {
        padding: 8px;
      }
    }

    &__text {
      color: $black;
      @include poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      @include dark {
        color: $white;
      }

      @include m {
        font-size: 12px;
      }
    }

    &__img-seedtoken {
      display: flex;
      justify-content: center;
      margin: 150px 0px 0px 0px;

      @include m {
        margin: 20px 0px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &_metrics {
      .box_holder {
        display: flex;
        // gap: 18px;
        flex-wrap: wrap;
        margin-top: 80px;

        @include t {
          margin-top: 50px;
        }

        .metric_box {
          flex: 0 0 25%;
          padding: 10px;
          &_inner {
            width: 100%;
            height: 294px;
            border-radius: 5px;
            border: 1.5px solid $neutrals6 !important;
            transition: all 0.2s ease;
            background: $primary2;

            @include dark {
              border: 1.5px solid $navy1 !important;

              background-color: #0d0d0d;
            }

            &:hover {
              box-shadow: 0px 12px 60px 0px #0000001a;
              border: 0;

              @include dark {
                box-shadow: 0px 12px 60px 0px #cacaca1a;
              }
            }

            @include m {
              height: 180px;
            }
          }

          @include t {
            flex: 0 0 50%;
          }
        }

        .metrics-info {
          margin-top: 15px;
          text-align: center;
        }

        .metrics-title {
          @include poppins;
          font-size: 18.8px;
          font-weight: 500;
          line-height: 32.23px;

          @include m {
            font-size: 11px;
            font-weight: 500;
            line-height: 20px;
          }
        }

        .metric_number {
          @include poppins;
          font-size: 16.11px;
          font-weight: 600;
          line-height: 26.86px;
          color: $primary3;
        }

        img {
          object-fit: contain;

          @include m {
            width: 80px;
            height: 80px;
          }
        }
      }

      .center_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__text2 {
    color: $neutrals4;
    @include poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding-right: 5px;
  }

  &__twitterCommunity {
    display: flex;
    justify-content: center;
    gap: 41.22px;
    background: $white;

    @include dark {
      background: $neutrals0;
    }

    @include m {
      display: grid;
    }

    &__section {
      width: 100%;
      padding: 13.74px 13.74px 44.66px 13.74px;
      gap: 41.22px;
      border-radius: 20.61px;
      border: 0.86px;
      background: $neutrals8;
      border: 0.86px solid $neutrals6;

      @include dark {
        background: $primary5;
        border: 0.86px solid $navy1;
      }
    }

    &__section1 {
      display: grid;
      padding: 41.22px 41.22px 0px 41.22px;
      gap: 30.92px;
    }

    &__section-like {
      display: grid;
      max-width: 389.88px;
      gap: 12.02px;
    }

    &__title {
      // font-family: Sora;
      @include poppins;
      font-size: 27.48px;
      font-weight: 400;
      line-height: 41.22px;
      text-align: left;

      @include m {
        font-size: 20px;
        line-height: 28.2px;
      }
    }

    &__section1-button {
      display: flex;
      align-items: center;
      gap: 30px;
      flex-wrap: wrap;

      @include m {
        gap: 20px;
      }

      @include r(350) {
        gap: 5px;
      }
    }

    &__button-disable {
      padding: 15.46px;
      border-radius: 13.74px;
      background: $neutrals6;

      @include dark {
        background: $navy1;
      }
    }

    &__button-active {
      padding: 20px 24px;

      backdrop-filter: blur(30px);
      border-radius: 13px;

      &:after {
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        border: 2.58px solid transparent;
        background: linear-gradient(91.42deg, #eacb76 5.41%, #eacb76 5.42%, #8e793e 94.07%);

        mask:
          linear-gradient(#ffffff 0 0) padding-box,
          linear-gradient(#ffffff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }
    }

    &__text {
      // font-family: Sora;
      @include poppins;
      font-size: 20.61px;
      font-weight: 400;
      line-height: 41.22px;
      text-align: left;
    }

    &__text2 {
      margin-top: 50px;
      display: flex;
      // font-family: Sora;
      @include poppins;
      font-size: 20.61px;
      font-weight: 400;
      line-height: 41.22px;
      text-align: left;

      @include m {
        display: flex;
        justify-content: center;
      }

      &_colorful {
        background: linear-gradient(91.46deg, #eacb76 20.64%, #eacb76 20.64%, #8e793e 90.9%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &__text3 {
      color: #757185;
      // font-family: Sora;
      @include poppins;
      font-size: 13.74px;
      font-weight: 300;
      line-height: 20.61px;
      text-align: left;
    }

    &__tablet {
      display: none;
      @include t {
        display: flex;
        margin-top: 30px;
      }
    }

    &__images {
      display: grid;
      padding: 41.22px 41.22px 0px 41.22px;
      gap: 30.92px;

      @include m {
        padding: 41.22px 0px 0px 0px;
      }
    }
  }

  &__cashClick {
    @include t {
      padding-bottom: 350px;
    }

    @include m {
      padding-bottom: 850px;
    }

    &__title {
      display: flex;
      margin-bottom: 10px;
      @include poppins;
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-align: left;

      @include t {
        font-size: 26px;
      }
    }

    &__tablet {
      display: none;

      @include t {
        display: block;
      }
    }

    &__haed {
      display: flex;
      flex-direction: row;
      @include hairline-3;
      color: $neutrals1;
      gap: 37px;
      font-size: 40px;

      @include dark {
        color: $neutrals8;
      }

      @include t {
        display: flex;
        justify-content: space-between;
      }
    }

    &__box {
      background-color: $primary1;
      padding: 67px 0 67px 0;
      border-top: 1px solid;
      border-image: linear-gradient(to right, #eacb76, #8e793e) 1 0 1 0;
      border-bottom: 1px solid;

      &.rv {
        padding: 40px 0;
        background-color: $primary1;

        @include dark {
          background-color: $primary2;
        }
      }

      // &::after {
      //   background: linear-gradient(124.64deg, #8f7816 29.19%, #ffd630 118.18%);
      //   border-top: 1px solid rgba(0, 0, 0, 0);
      //   border-bottom: 1px solid rgba(0, 0, 0, 0);
      //   border-image: linear-gradient(
      //     124.64deg,
      //     #8f7816 29.19%,
      //     #ffd630 118.18%
      //   ) !important;
      // }

      @include t {
        margin-top: 140px;
        height: 230px;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-left: 1px solid rgba($neutrals8, 0.4);
      padding-right: 20px;

      @include dark {
        border-left: 1px solid rgba($primary5, 0.4);
      }

      @include t {
        border-left: 1px solid rgba($primary5, 0.4);

        @include dark {
          border-left: 1px solid rgba($neutrals8, 0.4);
        }
      }
    }

    &__text {
      @include poppins;
      color: $neutrals7;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
      border-left: 2px solid $primary3;
      padding-left: 16px;

      &.rv {
        color: $neutrals7;

        @include dark {
          color: $primary1;
        }
      }

      // @include dark {
      //   color: $primary5;
      // }

      @include t {
        color: $primary5;
        font-size: 18px;

        @include dark {
          color: $neutrals8;
        }
      }
    }

    &__text1 {
      display: flex;
      @include poppins;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: -0.01em;
      text-align: left;
      margin-bottom: 30px;

      @include t {
        display: none;
      }
    }

    &__text1-mobile {
      display: none;

      @include t {
        display: flex;
        @include poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: $neutrals2;

        @include dark {
          color: $neutrals7;
        }
      }
    }

    &__text2 {
      padding-left: 16px;
      max-width: 300px;
      @include dm-sans;
      color: $neutrals7;
      font-size: 16px;
      font-weight: 400;
      line-height: 25.6px;
      letter-spacing: 0.30000001192092896px;
      text-align: left;
      margin-right: 30px;

      &.rv {
        color: $neutrals8;

        @include dark {
          color: $primary1;
        }
      }

      // @include dark {
      //   color: $primary5;
      // }

      @include t {
        color: $primary5;

        @include dark {
          color: $neutrals8;
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #151618;
      @include poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: right;
      background: linear-gradient(91.42deg, #eacb76 5.41%, #eacb76 5.42%, #8e793e 94.07%);
      padding: 16px;
      gap: 6px;
      height: 56px;
      min-width: 206px;

      @include dark {
        color: $primary5;
      }

      @include t {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      img {
        @include filter-primary1;
      }

      .btn_join {
        font-size: 11.11px;
        font-weight: 600;
        line-height: 20.15px;
        text-align: start;
        text-transform: uppercase;
      }

      .btn_title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20.15px;
        text-align: start;
        text-transform: capitalize;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;

      @include t {
        display: none;
      }
    }

    &__photo {
      position: relative;
      // margin-right: -60px;
      // margin-left: auto;
      // width: 516px;
      // height: 596px;\
      width: 40%;
      margin: -185px auto -158px;

      @include t {
        // margin: 0;
        // display: flex;
        // justify-content: center;
        width: 100%;
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -205px auto 0;

        // img {
        //   width: 100%;
        //   height: 100%;
        //   object-fit: contain;
        // }
      }

      img {
        // position: absolute;
        top: -260px;
        right: -80px;
        object-fit: contain;
        width: 100%;
        height: 100%;

        // @include x {
        //   width: 444px;
        // }

        // @include d {
        //   width: 350px;
        // }

        @include t {
          position: unset;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &__box-mobile {
      position: relative;
      display: flex;
      justify-content: center;
      margin: -50px 20px 0;
    }

    &__section-mobile {
      display: none;

      @include t {
        display: grid;
        padding: 32px;
        gap: 48px;
        // position: absolute;
        // top: -50px;
        background: $primary2;

        @include dark {
          background: $neutrals1;
        }
      }
    }

    &__section-text-mobile {
      display: none;

      @include t {
        display: flex;
      }

      @include m {
        display: grid;
        gap: 48px;
      }
    }

    &__btns {
      display: flex;
      margin-top: 48px;
      align-items: center;
      gap: 35px;

      @include t {
        width: 100%;
        margin-top: 0px;
        display: grid;
      }
    }

    &__goggle-play {
      display: flex;
      justify-content: center;
    }
  }

  &__awardsPtotos {
    &__color {
      color: $white !important;
    }

    &_flex {
      display: flex;
      justify-content: center;
      gap: 20px;
      @include t {
        gap: 6px;
        flex-wrap: wrap;
      }
    }

    &__section {
      border: none;
      width: 100%;
      display: grid;
      position: relative;

      @include t {
        flex: 0 0 49%;

        &:last-child {
          flex: 1 0 49.1%;
        }
      }

      &::after {
        background: linear-gradient(124.64deg, #8f7816 29.19%, #ffd630 118.18%);
        // border: 0.5px solid #0000;
        border: 1px solid #0000;
        border-image-source: linear-gradient(124.64deg, #8f7816 29.19%, #ffd630 118.18%) !important;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        bottom: 0;
        content: "";
        left: 0;
        // margin: 10px;
        mask:
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        -webkit-mask:
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        position: absolute;
        right: 0;
        top: 0;
        // z-index: -1;
      }

      .inner {
        background-color: transparent;
        padding: 26px;
        background-color: $primary1;
        border-radius: 4px;
        display: grid;
        gap: 26px;
        height: 100%;
        transition: all 0.2s ease;

        @include t {
          display: flex;
          flex-direction: column;
          gap: 14px;
          padding: 12px;
        }

        @include m {
          gap: 10px;
          padding: 8px;
        }
      }

      &:hover {
        .inner {
          background-color: #1d1d1d;
        }
      }

      .image_holder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 312px;
        z-index: 1;

        @include t {
          height: 120px;
        }
      }
    }

    &__title {
      @include poppins;
      font-size: 21.89px;
      font-weight: 500;
      line-height: 34.6px;
      text-align: start;
      z-index: 1;

      @include t {
        text-align: center;
        font-size: 24px;
        line-height: 20px;
      }

      @include m {
        text-align: center;
        font-size: 16px;
        line-height: 10px;
      }
    }

    &__text {
      @include poppins;
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      text-align: start;
      color: #b1b5c3;
      z-index: 1;

      @include t {
        font-size: 18px;
        line-height: 26px;
        text-align: center;
      }

      @include m {
        font-size: 10px;
        line-height: 16px;
        text-align: center;
      }
    }

    &__img {
      object-fit: contain;

      @include t {
        width: 90px;
        height: 90px;
      }
    }

    .hero_awards {
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: contain;
      background-image: url("../../../public/images/awards-photo/seed-awards-photo-image-background.webp");
      height: 485px;

      @include t {
        background-size: cover;
        height: 320px;
      }
    }

    .awards_text {
      font-family: "ClashDisplay-Semibold";
      font-size: 180px;
      font-weight: 600;
      line-height: 140px;
      text-align: center;
      color: #cccccc;
      opacity: 0.3;
      margin-top: -74px;

      @include m {
        font-size: 58.59px;
        line-height: 45.57px;
      }
    }

    .photos_text {
      font-family: "ClashDisplay-Semibold";
      font-size: 80px;
      font-weight: 600;
      line-height: 140px;
      text-align: center;
      margin-top: -70px;
      position: relative;
      z-index: 1;

      @include m {
        font-size: 26.04px;
        line-height: 45.57px;
        margin-top: -20px;
      }
    }

    .awards_description {
      @include poppins;
      font-size: 16.42px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      color: #b1b5c3;
      @include m {
        margin-top: 10px;
      }
    }

    .awards_summary {
      margin: 100px 40px;
      display: flex;
      align-items: center;

      font-family: "ClashDisplay-Semibold";
      font-size: 30px;
      font-weight: 500;
      line-height: 140px;
      text-align: start;

      @include t {
        margin: 100px 22px;
        flex-direction: column;
        font-size: 26px;
        font-weight: 500;
        line-height: 80.41px;
        text-align: center;
        justify-content: center;
      }

      @include m {
        margin: 100px 22px;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        line-height: 43.41px;
        text-align: center;
        justify-content: center;
      }

      .first {
        margin-right: auto;
        @include t {
          margin: 0;
        }
      }

      b {
        font-family: "ClashDisplay-Bold";
        font-weight: 700;
        color: $primary3;
      }
    }

    .highway {
      margin: 78px 0;

      .noback {
        .card2 {
          padding: 0;
          background-color: transparent;

          .card2__box {
            padding: 0 5px;
          }

          &.portrait {
            max-width: 200px;
          }

          &.landscape {
            // max-width: 350px;
            max-width: 410px;
          }

          @include m {
            &.portrait {
              height: 120px;
              max-width: 90px;
              margin: 0 0px 20px;
            }

            &.landscape {
              height: 120px;
              max-width: 190px !important;
              margin: 0 0px 20px;
            }

            .card2__preview {
              height: 120px;
            }
          }
        }
      }

      .recently__center .swiper-slide {
        margin: 0;
        @include m {
          width: 35% !important;
        }
      }
    }

    .awards_logo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 15px 0;
      margin-bottom: 78px;

      .logo_item {
        flex: 1 0 14.285%;
        text-align: center;

        img {
          object-fit: contain;
        }
      }
    }

    .awards_gallery {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px 0;

      @include m {
        gap: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .gallery_item {
        flex: 1 0 14.285%;
        text-align: center;

        @include m {
          flex: 0 0 20%;
          width: 20%;
          height: 43px;
          text-align: center;
          padding: 4px;
        }

        img {
          object-fit: contain;

          @include m {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &__featureIn {
    &__pc {
      display: block;

      @include m {
        display: none;
      }
    }

    &_center {
      justify-content: center;
    }

    &_width {
      @include r(550) {
        width: 50%;
      }
      @include r(450) {
        width: 60%;
      }
      @include r(370) {
        width: 80%;
      }
    }

    &_margin-r {
      @include r(550) {
        margin-right: 0px !important;
      }
    }

    .featurein {
      margin: 0 auto;
    }
  }

  // &__meuseum {
  // }

  &__ShowCase {
    &__title {
      margin-bottom: 5px;
      color: $neutrals1;
      @include dm-sans;
      font-size: 48px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -0.02em;
      text-align: left;

      @include dark {
        color: $white;
      }

      @include t {
        font-size: 38px;
        text-align: center;
      }

      @include m {
        margin: 10px 0px;
        text-align: left;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.01em;
      }
    }

    &__padding {
      padding: 12px 23px !important;
    }

    &__btns {
      gap: 5px;
      justify-content: flex-start !important;

      @include t {
        justify-content: center !important;
      }

      @include m {
        justify-content: flex-start !important;
      }
    }

    &__margin {
      margin: 0px 0px 0px 10px !important;

      @include m {
        margin: 5px 10px !important;
      }
    }

    &__margin2 {
      margin: 0px !important;

      @include m {
        margin: 5px 0px !important;
      }
    }

    &__join {
      width: 713.01px;

      @include d {
        width: 400px;
      }

      @include t {
        width: 400px;
        margin: auto;
      }

      @include m {
        width: 100%;
        margin: auto;
      }
    }

    &__description-color {
      margin-top: 15px;
      color: $neutrals3;

      @include dark {
        color: $neutrals4;
        @include m {
          margin-top: 25px;
        }
      }

      @include m {
        margin-top: 25px;
      }
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 713.01px;
      /*height: 56px;*/

      @include d {
        width: 400px;
        /*height: 80px;*/
      }

      @include t {
        width: 400px;
        /*height: 96px;*/
        margin: auto;
      }

      @include m {
        width: 100%;
        text-align: start;
        /*height: 96px;*/
        margin: auto;
      }

      @include s {
        width: 100%;
        text-align: start;
        /*height: 96px;*/
      }
    }

    &__stage {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: $neutrals4;
      text-transform: uppercase;

      img {
        // width: 24px;
        width: auto;
        // height: auto;
        margin-right: 8px;
        filter: brightness(0) saturate(100%) invert(58%) sepia(3%) saturate(1985%) hue-rotate(186deg) brightness(84%)
          contrast(89%);
      }

      @include t {
        justify-content: center;
      }

      @include m {
        justify-content: flex-start;
      }
    }

    &__gallery {
      margin: auto 0 auto auto;
      width: 300px;

      @include t {
        margin: 0 auto;
      }

      @include m {
        width: 60%;
      }

      @include s {
        width: 90%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &_center {
      @include m {
        @include dark {
          background: $black !important;
        }
      }
    }
  }
}

.text {
  &_colorful {
    background: linear-gradient(91.87deg, #eacb76 28.6%, #eacb76 28.6%, #8e793e 89.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.container-slider {
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.container-slider2 {
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.slider1 {
  display: flex;
  width: calc(10 * 100%);
  animation: slide1 80s linear infinite;
}

.slider2 {
  display: flex;
  width: calc(10 * 100%);
  animation: slide2 80s linear infinite;
}

.slider_museum {
  display: flex;
  width: calc(10 * 100%);
  animation: slide1 200s linear infinite;
}

.slider_museum_reverse {
  display: flex;
  width: calc(10 * 100%);
  animation: slide2 200s linear infinite;
}

@keyframes slide1 {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.container-slider-all:hover {
  .slider1,
  .slider2,
  .slider_museum,
  .slider_museum_reverse {
    animation-play-state: paused;
  }
}

.home-card-width {
  @include m {
    flex: 0 0 10%;
    width: 100% !important;
  }
}

.museum-card-height {
  @include m {
    height: 250px !important;
  }
}

.sandbox {
  padding: 120px 0;

  &_section_img {
    // margin-bottom: 26px;
    height: 100px;
    display: flex;
    align-items: center;

    @include dark {
      @include filter-primary1;
    }

    @include m {
      @include filter-primary1;

      @include dark {
        @include filter-primary2;
      }
    }
  }

  .home__cashClick__description {
    min-height: 145px;
  }

  .home__cashClick__button {
    @include rmin(1023) {
      width: fit-content;
    }
  }

  .home__cashClick__photo {
    width: 36%;
    margin: -185px auto -200px;

    @include t {
      width: 100%;
      margin: -300px auto 0px;
      height: 100%;
    }
  }

  .home__cashClick__box-mobile {
    margin: 85px 0 0;
  }

  .home__cashClick__box {
    @include t {
      margin-top: 250px;
    }
  }
}
